var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '../../common/components/Flex';
import { Grid, GridItem } from '../../common/components/Grid';
import Separator from '../../common/components/Separator';
import TrustSealsItem from './TrustSealsItem';
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Container.defaultProps = __assign(__assign({}, Flex.defaultProps), { justifyContent: 'center', alignItems: 'center', flexDirection: ['column', 'column', 'row'], px: ['sp_8', 'sp_12', 'sp_0'], pt: ['sp_16', 'sp_20', 'sp_0'], pb: ['sp_16', 'sp_20', 'sp_24'] });
var StyledSeparator = styled(Separator)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
StyledSeparator.defaultProps = __assign(__assign({}, Separator.defaultProps), { mb: [null, null, 'sp_24'], display: ['none', null, 'block'] });
var TrustSeals = function (_a) {
    var items = _a.items;
    return (React.createElement(Grid, null,
        React.createElement(GridItem, null,
            React.createElement(StyledSeparator, null),
            React.createElement(Container, { "data-testid": "trust-seals" }, items.map(function (item, index) { return (React.createElement(TrustSealsItem, __assign({ key: index }, item))); })))));
};
export default TrustSeals;
var templateObject_1, templateObject_2;
