var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { fontSize, lineHeight, space, themeGet } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import RichTextBlock from '../../common/components/RichText';
import { breakpoint } from '../../common/theme/helper';
import TrustSealsItemIcon from './TrustSealsItemIcon';
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & + & {\n    ", " {\n      margin-left: ", "px;\n    }\n    ", " {\n      margin-left: ", "px;\n    }\n  }\n"], ["\n  & + & {\n    ", " {\n      margin-left: ", "px;\n    }\n    ", " {\n      margin-left: ", "px;\n    }\n  }\n"])), breakpoint(1), themeGet('space.sp_24'), breakpoint(2), themeGet('space.sp_32'));
Container.defaultProps = __assign(__assign({}, Flex.defaultProps), { flexDirection: ['column', null, 'row'], justifyContent: 'center', alignItems: 'center', flexWrap: 'inherit', width: [null, 1], mb: ['sp_16', 'sp_20', 'sp_0'], px: ['sp_8', 'sp_12', 'sp_0'], textAlign: ['center', null, 'left'] });
var IconWrapper = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
IconWrapper.defaultProps = {
    mr: 'sp_8',
};
var Text = styled(RichTextBlock)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && p {\n    ", ";\n    ", ";\n    color: ", ";\n    margin: ", ";\n  }\n"], ["\n  && p {\n    ", ";\n    ", ";\n    color: ", ";\n    margin: ", ";\n  }\n"])), fontSize, lineHeight, themeGet('colors.sys.neutral.text.default'), themeGet('space.sp_0'));
Text.defaultProps = {
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_20', null, null, 'lh_24'],
};
var getLinkProps = function (link, icon) {
    var _a;
    return ({
        as: 'a',
        href: link.url,
        target: link.target,
        'aria-label': (_a = icon === null || icon === void 0 ? void 0 : icon.alt) !== null && _a !== void 0 ? _a : null,
        rel: 'noreferrer',
    });
};
var TrustSealsItem = function (_a) {
    var link = _a.link, icon = _a.icon, text = _a.text;
    var iconWrapperProps = (link === null || link === void 0 ? void 0 : link.url) ? getLinkProps(link, icon) : null;
    return (React.createElement(Container, null,
        icon && (React.createElement(IconWrapper, __assign({}, iconWrapperProps),
            React.createElement(TrustSealsItemIcon, { url: icon.url, alt: icon.alt }))),
        text && React.createElement(Text, { dangerouslySetInnerHTML: { __html: text } })));
};
export default TrustSealsItem;
var templateObject_1, templateObject_2, templateObject_3;
