var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, height } from 'styled-system';
import { transformPrismicImageUrl } from '../../common/utils/cdn/prismic';
var ICON_SIZE = 32;
var Wrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
Wrapper.defaultProps = {
    display: 'block',
};
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), height);
Image.defaultProps = {
    height: ICON_SIZE,
};
var TrustSealsItemIcon = function (_a) {
    var url = _a.url, alt = _a.alt;
    var src = transformPrismicImageUrl(url, ICON_SIZE);
    return (React.createElement(Wrapper, null,
        React.createElement(Image, { loading: "lazy", src: src, alt: alt !== null && alt !== void 0 ? alt : '' })));
};
export default TrustSealsItemIcon;
var templateObject_1, templateObject_2;
